<ion-header>
  <ion-toolbar color="primary">
    <ion-title>About Room Ruler</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismissModal()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-segment [(ngModel)]="selectedTab">
    <ion-segment-button value="about">
      <ion-label>About Us</ion-label>
    </ion-segment-button>
    <ion-segment-button value="why">
      <ion-label>Why Choose Us</ion-label>
    </ion-segment-button>
    <ion-segment-button value="contact">
      <ion-label>Contact Us</ion-label>
    </ion-segment-button>
  </ion-segment>

  <div [ngSwitch]="selectedTab">
    <div *ngSwitchCase="'about'">
      <div class="text-center mb-2">
        <img src="../../../../assets/logo-dark.png" width="300px" alt="Room Ruler Logo">
      </div>
      <p>Room Ruler is a premier, secure platform designed to streamline and enhance the management of arcade and slot machine businesses. Our comprehensive suite of features ensures that every aspect of your business is managed efficiently, effectively, and securely.</p>

      <h4>Our Mission</h4>
      <p>At Room Ruler, our mission is to empower arcade and slot machine businesses with the tools they need to thrive. We aim to simplify complex management tasks, allowing you to focus on providing an outstanding experience for your customers.</p>

      <h4>What We Offer</h4>
      <ul>
        <li><strong>Employees Management:</strong> Easily manage your staff with our intuitive employee management system. Track work schedules, performance, and more to ensure your team operates smoothly.</li>
        <li><strong>Employees Bank Management:</strong> Handle all financial transactions related to your employees seamlessly. Our platform ensures accurate and secure management of salaries, bonuses, and other financial matters.</li>
        <li><strong>Machine and Assets Management:</strong> Keep track of all your arcade and slot machines, as well as other valuable assets. Our system allows you to monitor performance, schedule maintenance, and manage inventory effectively.</li>
        <li><strong>Expenses:</strong> Monitor and control your expenses with ease. Room Ruler provides detailed insights into your spending, helping you to optimize your budget and reduce unnecessary costs.</li>
        <li><strong>Payroll:</strong> Simplify payroll processing with our automated system. Ensure your employees are paid accurately and on time, every time.</li>
        <li><strong>Member Management:</strong> Manage your membership program effortlessly. Keep track of member information, preferences, and activities to enhance their experience and loyalty.</li>
        <li><strong>Member Check-in:</strong> Streamline the check-in process for your members. Our platform makes it quick and easy, ensuring a smooth entry experience and efficient record-keeping.</li>
      </ul>
    </div>

    <div *ngSwitchCase="'why'">
      <h4>Why Choose Room Ruler?</h4>
      <p><strong>Security First:</strong> We understand the importance of security in your business operations. Room Ruler employs advanced security measures to protect your data and ensure your peace of mind.</p>
      <p><strong>User-Friendly Interface:</strong> Our platform is designed with user experience in mind. Navigate through features effortlessly with our intuitive and easy-to-use interface.</p>
      <p><strong>Comprehensive Support:</strong> We are committed to providing exceptional support. Our team is always ready to assist you with any questions or issues, ensuring you get the most out of Room Ruler.</p>
    </div>

    <div *ngSwitchCase="'contact'">
      <h4>Contact Us</h4>
      <p>For more information or to schedule a demo, please contact us at:</p>
      <ul>
        <li><strong>Email:</strong> <span> support&#64;roomruler.com</span></li>
        <li><strong>Phone:</strong> (346) 481-0059</li>
      </ul>

      <p>Thank you for choosing Room Ruler, where management meets innovation.</p>
    </div>
  </div>
</ion-content>
